.top-section{
    // margin-top: 188px;
    position: relative;
    height: 79vh;
    overflow: hidden;
    img{
        object-fit: cover;
        height: 100%;
    }
    .overlay-dark {
        background-color: rgba(black, 0.4);
    }
    .allocate-finance{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        h1{
            font-family: $roboto-bold;
            font-size: 48px;
            line-height: 40px;
            letter-spacing: 0.2px;
            color: var(--font-color-button);
        }
        p{
            font-family: $roboto-regular;
            color: var(--font-color-button);
        }
        .buttons {
            display: flex; /* Flex container for the buttons */
            gap: 15px; /* Space between the buttons */
        }
    }
    .subUnsubBtn{
        bottom: 10px;
        snl-button.sub button{
            background-color: var(--semantic-9) !important;
            &:hover{
                background-color: var(--semantic-12) !important;
            }
        }
        snl-button.unsub button{
            background-color: var(--neutral-12) !important;
            color: var(--neutral-1);
            &:hover{
                background-color: var(--neutral-9) !important;
            }
        }
    }
}