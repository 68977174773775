snl-icon:focus-visible{
  outline: none;
}
.wrapper{
  padding: 0 15px;
}
.modal__wrapper-medium{
    position: relative !important;
    top: 50px !important;
    height: auto !important;
    @media #{$media-above-992} {
      width: 850px !important;
    }
    @media #{$media-below-992} {
      width: 100% !important;
    }
    @media #{$media-below-451} {
      flex-direction: inherit !important;
    }
    .input-wrapper{
        margin-bottom: 20px;
    }
    .input-wrapper:first-child{
        margin-top: 10px;
    }
}
.modal__header{
  @media #{$media-below-451} {
    order: inherit !important;
  }
}
.modal__content-medium{
  @media #{$media-below-451} {
    padding-bottom: inherit !important;
  }
}
.modal__footer{
  @media #{$media-below-451} {
    flex: inherit !important;
    height: inherit !important;
    margin-bottom: inherit !important;
  }
}
#messageContainer {
  position: relative;
  float: left;
  width: 100%;
  margin-right: 10%;
  label {
    position: absolute;
    top: 0;
    left: 8px;
    padding: 8px 0;
    pointer-events: none;
    transition: all 0.15s ease;
    color: var(--font-color-secondary);
  }
  textarea {
    font-family: var(--font-family-02);
    height: 180px;
    background-color: transparent;
    font-size: 1rem;
    border: 0;
    border: 1px solid var(--neutral-9);
    width: 100%;
    border-radius: 4px;
    min-height: 7rem;
    resize: none;
    padding:8px;
    &:focus,
    &.not-empty {
      outline: 0;
      border: 1px solid var(--primary-default);
    }
    &:focus ~ label,
    &.not-empty ~ label {
      font-size: 0.7rem;
      color: var(--primary-default);
      top: -1.25rem;
      transition: all 0.15s ease;
      background: white;
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 0px;
    }
  }
}

/* The SIMPLE-TOAST - position it at the bottom and in the middle of the screen */
#successToast, #failureToast {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
    font-family: monospace;
    display: inline-flex;
    span {
      margin-left: 12px;
      margin-top: 2px;
    }
    &.show {
      visibility: visible; /* Show the SIMPLE-TOAST */
      /* Add animation: Take 0.5 seconds to fade in and out the SIMPLE-TOAST.
      However, delay the fade out process for 2.5 seconds */
      -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
      animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
  }
  #successToast{
    background-color: var(--semantic-1); /* Background color */
  }
  #failureToast{
    background-color: var(--semantic-9); /* Background color */
  }
  
  /* Animations to fade the SIMPLE-TOAST in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  